<template>
  <div class="auth-form-wrapper">
    <div class="auth-form-container">
      <div class="auth-form">
        <ShowTitle text="註冊" type="title" />
        <i
          @click="$router.push({ path: '/' })"
          class="
            auth-close-btn
            pointer
            el-icon-close
            basic-hover
            ease-in-out-03s
            font-gray
          "
        ></i>
        <el-form>
          <el-form-item>
            <el-input
              style="width: 260px"
              placeholder="電子郵件"
              type="email"
              v-model="dataObj.email"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              :type="isPassword"
              style="width: 260px"
              placeholder="密碼"
              v-model="dataObj.password"
            >
              <span
                slot="suffix"
                @click="
                  isPassword = isPassword === 'password' ? '' : 'password'
                "
              >
                <i
                  class="el-icon"
                  :class="isPassword ? 'el-icon-lock' : 'el-icon-unlock'"
                />
              </span>
            </el-input>
          </el-form-item>
        </el-form>
        <div class="form-button-container">
          <el-button
            class="ease-in-out-03s basic-submit-btn"
            :loading="submitLoading"
            @click="HandleSubmit()"
          >
            註冊
          </el-button>
          <div class="margin-top-xs font-ccc text-xs">
            註冊即表示你同意
            <router-link
              class="ease-in-out-03s basic-hover font-blue"
              to="/legal/terms-of-service"
              target="_blank"
            >
              使用條款
            </router-link>
            和
            <router-link
              class="ease-in-out-03s basic-hover font-blue"
              to="/legal/privacy-policy"
              target="_blank"
            >
              隐私政策
            </router-link>
          </div>
          <div class="margin-top-xl margin-bottom-xs">已經有帳號了嗎？</div>
          <router-link
            class="ease-in-out-03s basic-hover font-blue"
            to="/user/signin"
          >
            登入
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainCommon from "@/utils/mainCommon";
import { userSignup } from "@/api/user";
import Cookies from "js-cookie";
import SHA256 from "crypto-js/sha256";

export default {
  mixins: [mainCommon],
  data() {
    return {
      isPassword: "password",
      submitLoading: false,
      dataObj: {
        email: null,
        name: null,
        password: null,
      },
    };
  },
  created() {
    this.HandleAuthorized();
  },
  methods: {
    HandleSubmit() {
      this.submitLoading = true;
      this.dataObj.password = SHA256(this.dataObj.password).toString();
      userSignup(this.dataObj)
        .then((res) => {
          this.pushNotification(res.message);
          Cookies.set("emailVerify", this.dataObj.email);
          setTimeout(() => {
            this.$router.push({ path: "/user/email-verify" });
            this.submitLoading = false;
          }, 500);
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-input .el-input__inner {
  padding: 0 15px;
}
.el-icon {
  margin-right: 10px;
}
</style>